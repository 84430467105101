<template>
  <div class="about">
    <h1>Download Launchpad</h1>
    <p>To download a recent release of Launchpad, simply click the link below!</p>
    <div class="download-section">
      <a class="download-button" :href="installer"> Download Launchpad {{ version }}
      </a>

      <div class="download-requirements">Launchpad requires Windwos 7, 8, or 10. It
        does not require administrative privileges.</div>

      <div class="download-release-page">
        You can also visit the <a class="download-more-info" :href="releasePage">Launchpad
          {{version}} release page</a>.
      </div>
    </div>
    <h2>GitHub Releases</h2>
    <p>Note: There may be a more recent installer available on GitHub.
      You can always find the latest release page
      <a href="https://github.com/VolantisDev/Launchpad/releases/latest">here</a>.</p>
  </div>
</template>

<style lang="stylus">
  .download-section
    display flex
    flex-direction column

  .download-button
    display block
    margin 1em 0
    width 400px
    display block
    background #7A9C04
    padding 1em 2em
    color #ffffff
    text-decoration none
    text-transform uppercase
    font-weight bold
    font-size 1.2em

    &:hover
      background #89AE05

  .download-requirements
    font-size: 0.9em

  .download-release-page
    margin-top 1em
</style>

<script lang="ts">
import { Vue } from 'vue-class-component';
import { getFirestore, doc, getDoc } from 'firebase/firestore/lite';

export default class Download extends Vue {
  installer = 'https://github.com/VolantisDev/Launchpad/releases/download/9.0.0/Launchpad-8.0.1.exe';

  releasePage = 'https://github.com/VolantisDev/Launchpad/releases/tag/8.0.1';

  version = '8.0.1';

  mounted() {
    const db = getFirestore(this.$firebase);
    const docRef = doc(db, 'release-info', 'stable');

    getDoc(docRef).then((document) => {
      if (document.exists()) {
        const data = document.data();
        this.installer = data.installer;
        this.releasePage = data['release-page'];
        this.version = data.version;
      }
    });
  }
}
</script>

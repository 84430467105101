
import { Vue } from 'vue-class-component';
import { getFirestore, doc, getDoc } from 'firebase/firestore/lite';

export default class Download extends Vue {
  installer = 'https://github.com/VolantisDev/Launchpad/releases/download/9.0.0/Launchpad-8.0.1.exe';

  releasePage = 'https://github.com/VolantisDev/Launchpad/releases/tag/8.0.1';

  version = '8.0.1';

  mounted() {
    const db = getFirestore(this.$firebase);
    const docRef = doc(db, 'release-info', 'stable');

    getDoc(docRef).then((document) => {
      if (document.exists()) {
        const data = document.data();
        this.installer = data.installer;
        this.releasePage = data['release-page'];
        this.version = data.version;
      }
    });
  }
}
